import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useData } from "contexts/DataContext";
import {
  Container,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Box,
  Button,
  Tooltip,
} from "@mui/material";

const EventCheckInPage = () => {
  const eventID = "recuff736x9Sj2EQP";
  const { events, teams, loading, error, updateExistingRecord, setTeams } =
    useData();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [newProjectTitle, setNewProjectTitle] = useState("");

  const event = events.find((e) => e.id === eventID);

  const sortTeamsByTableLocation = (teams) => {
    return teams.sort((a, b) => {
      const tableA = a.data["robotics_table_location"];
      const tableB = b.data["robotics_table_location"];
      const [sectionA, numberA] = [tableA[0], parseInt(tableA.slice(1), 10)];
      const [sectionB, numberB] = [tableB[0], parseInt(tableB.slice(1), 10)];
      if (sectionA === sectionB) {
        return numberA - numberB;
      }
      return sectionA.localeCompare(sectionB);
    });
  };

  useEffect(() => {
    if (event) {
      const eventTeams = event.data.teams
        .map((teamID) => teams.find((team) => team.id === teamID))
        .filter((team) => team !== undefined); // Filter out undefined teams
      const sortedTeams = sortTeamsByTableLocation(eventTeams);
      console.log(`${eventID} teams:`, sortedTeams);
      setFilteredTeams(sortedTeams);
    }
  }, [event, teams]);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    if (event) {
      const eventTeams = event.data.teams
        .map((teamID) => teams.find((team) => team.id === teamID))
        .filter((team) => team !== undefined); // Filter out undefined teams
      const filtered = eventTeams.filter(
        (team) =>
          team.data["Team PK"].toLowerCase().includes(term) ||
          team.data.rollup_team_members_names.toLowerCase().includes(term) ||
          (team.data.team_leader_email &&
            team.data.team_leader_email.some((email) =>
              email.toLowerCase().includes(term)
            ))
      );
      const sorted = sortTeamsByTableLocation(filtered);
      setFilteredTeams(sorted);
    }
  };

  const handleOpenModal = (team) => {
    setSelectedTeam(team);
    setNewProjectTitle(team.data["robotics_project_title"] || "");
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedTeam(null);
    setNewProjectTitle("");
  };

  const handleSaveProjectTitle = async () => {
    if (selectedTeam) {
      try {
        const updatedRecord = await updateExistingRecord(
          "teams",
          selectedTeam.id,
          {
            robotics_project_title: newProjectTitle,
          }
        );
        console.log("Updated project title:", updatedRecord);
        setTeams((prevTeams) =>
          prevTeams.map((team) =>
            team.id === selectedTeam.id
              ? {
                  ...team,
                  data: {
                    ...team.data,
                    robotics_project_title: newProjectTitle,
                  },
                }
              : team
          )
        );
        handleCloseModal();
      } catch (error) {
        console.error("Error updating project title:", error);
      }
    }
  };

  if (loading) {
    return <Typography>Loading event and team information...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  if (!event) {
    return <Typography>Event not found</Typography>;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {event.data["Event Name"]} Check-In and Project Lookup
      </Typography>
      <TextField
        fullWidth
        label="Search Teams"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Type in student name, team ID, or registered email to search for your team"
        sx={{ mb: 3 }}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Table Location</TableCell>
              <TableCell>Team Name</TableCell>
              <TableCell>Project Title</TableCell>
              <TableCell>Team Members</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTeams.map((team) => (
              <TableRow key={team.id}>
                <TableCell>{team?.data["robotics_table_location"]}</TableCell>
                <TableCell>{team?.data["Team PK"]}</TableCell>
                <TableCell>
                  <Tooltip title="Click to edit project title">
                    <span
                      onClick={() => handleOpenModal(team)}
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                    >
                      {team?.data["robotics_project_title"] ||
                        "Click here to enter a project title"}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell>{team?.data?.rollup_team_members_names}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Edit Project Title
          </Typography>
          <TextField
            fullWidth
            label="Project Title"
            variant="outlined"
            value={newProjectTitle}
            onChange={(e) => setNewProjectTitle(e.target.value)}
            sx={{ mt: 2, mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveProjectTitle}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCloseModal}
            sx={{ ml: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

EventCheckInPage.propTypes = {
  eventID: PropTypes.string.isRequired,
};

export default EventCheckInPage;
