import React, { createContext, useState, useContext, useEffect } from "react";
import {
  fetchAllRecords,
  //   fetchRecord,
  //   createRecord,
  //   createRecords,
  updateRecord,
  //   deleteRecord,
} from "config/airtable";

// Create DataContext
const DataContext = createContext();

// DataProvider to wrap your app
export const DataProvider = ({ children }) => {
  const [students, setStudents] = useState([]);
  const [teams, setTeams] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch all records from a table
  const fetchData = async (table, setData) => {
    try {
      const records = await fetchAllRecords(table);
      setData(records);
      console.log(`Fetched ${records.length} records from table "${table}"`);
      console.log(records);
    } catch (err) {
      setError(err.message);
    }
  };

  // Update an existing record
  const updateExistingRecord = async (table, recordId, fields) => {
    try {
      const updatedRecord = await updateRecord(table, recordId, fields);
      console.log(`Updated record in table "${table}":`, updatedRecord);
      return updatedRecord;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Fetch data on mount
  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      setError(null);
      try {
        await Promise.all([
          fetchData("students", setStudents),
          fetchData("teams", setTeams),
          fetchData("events", setEvents),
        ]);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  const value = {
    students,
    setStudents,
    teams,
    setTeams,
    events,
    setEvents,
    loading,
    error,
    fetchData,
    // fetchSingleRecord,
    // createNewRecord,
    // createMultipleRecords,
    updateExistingRecord,
    // deleteExistingRecord,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

// Custom hook for accessing DataContext
export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useData must be used within a DataProvider");
  }
  return context;
};
